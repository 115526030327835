@tailwind base;
@tailwind components;
@tailwind utilities;

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display:none;
}
.circle-operate {
  width: 50px;
  border-radius: 50%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.circle-operate.small {
  width: 20px;
  border-radius: 50%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.border-active {
  border: 1px solid #13489d!important;
}
.hide-header .ant-page-header-heading {
  display: none;
}
.ant-pagination-options .ant-select-selector {
  height: 32px!important;
}
.ant-pagination-options .ant-select-selection-search-input {
  height: 30px!important;
  line-height: 30px!important;
}
.ant-pagination-options .ant-select-selection-item {
  line-height: 30px!important;
}