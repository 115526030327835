.button-header {
  height: 100%;
  padding: 20px;
  color: #000000;
  background-color: #aca8a8;
  background-image: -moz-linear-gradient(top, #aca8a8, #d2cccc);
  background-image: -ms-linear-gradient(top, #aca8a8, #d2cccc);
  background-image: -webkit-linear-gradient(top, #aca8a8, #d2cccc);
  background-image: -o-linear-gradient(top, #aca8a8, #d2cccc);
  background-image: linear-gradient(top, #aca8a8, #d2cccc);
  background-repeat: repeat-x;
  border: 1px solid gray;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 40%);
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 20%), 0 1px 2px rgb(0 0 0 / 50%);
  transition: all .3s ease-in-out;
}
.button-header:hover {
  filter: none;
  background-color: #aca8a8;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
  color: #000000;
}
