.review-block {
  background: #13489D;
  border-radius: 10px;
  padding: 10px;
  color: #ffffff;
  margin-bottom: 20px;
}
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content {
  color: #AEDAFF;
  font-weight: 400;
}
.images .ant-image {
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.circle-operate {
  width: 50px;
  border-radius: 50%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}