@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

// There are some major variables below,
// all less variables could be found in
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@100;200;300;400;500;600;700;800;900&display=swap");

// font
@font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// base variables
@border-radius-base: 6px;

// colors
@primary-color: #13489d;
@error-color: #fa541c;
@text-color: #626262;

// sider
@layout-sider-background: #2a132e;
@menu-bg: transparent;
@menu-item-color: #fff;
@menu-highlight-color: #fff;
@menu-item-active-bg: transparent;
@menu-item-active-border-width: 0px;
@layout-trigger-background: rgba(0, 0, 0, 0.5);

//form
@label-color: @text-color;
.logo-main-collapse {
  width: 80px;
  margin: 0 auto;
  padding: 12px 24px;
}
.logo-main {
  width: 110px;
  margin: 0 auto;
  padding: 12px 24px;
  display: block;
}
.ant-menu {
  font-size: 16px;
}
.side-bar-item .ant-menu-title-content {
  display: flex;
  align-items: center;
  width: 100%;
  i {
    margin-right: 10px;
    width: 14px;
  }
  > div {
    width: 100%;
  }
}
.ant-table {
  font-size: 16px;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px;
}
.ant-form-item-label > label {
  font-weight: bold;
}
.ant-page-header {
  border-radius: @border-radius-base;
}
.ant-table-permission .ant-form-item {
  margin-bottom: 0;
}
.ant-table-wrapper {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #eaeaea;
  padding: 8px;
  box-shadow: 0 4px 5px -3px rgb(0 0 0 / 10%);
}
.ant-page-header {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -4px rgb(0 0 0 / 10%);
  border-radius: 8px;
}
.ant-popover-inner-content {
  padding: 8px 14px;
}
.ant-table-thead > tr > th {
  padding: 6px 16px;
  background-color: rgba(248, 250, 252, 1);
  text-transform: uppercase;
}
.text-base {
  font-size: 16px;
  line-height: 20px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-affix-wrapper,
.ant-input {
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  box-shadow: 0 1px 3px 0 rgb(0 0 0/0.1),0 1px 2px -1px rgb(0 0 0/0.1);
  border-radius: 6px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 42px;
}
.currency-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 30px !important;
}
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 40px;
}
.currency-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 30px;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px;
}
.currency-select .ant-select-selector .ant-select-selection-item,
.currency-select .ant-select-selector .ant-select-selection-placeholder {
  line-height: 30px;
}
.ant-select,
.ant-input,
.ant-picker,
.ant-input-number-input,
.ant-input-affix-wrapper {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #374151;
}
.ant-input,
.ant-input-password,
.ant-input-affix-wrapper {
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
}
.ant-input-affix-wrapper {
  padding-top: 0.5rem !important;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem !important;
  padding-left: 0.75rem;
}
.ant-select-dropdown {
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 1px 5px 1px rgba(11, 57, 133, 0.23);
  border-radius: 6px;
}
.ant-select-arrow .anticon > svg,
.ant-select-arrow .anticon > svg path {
  fill: #6b7280;
}
.ant-picker {
  width: 100%;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 9px 13px;
}
.ant-input-number {
  width: 100%;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 5px 0;
}
.ant-picker-input .ant-picker-clear {
  padding: 2px 4px;
}
.ant-input-number-disabled {
  background: #f0f0f0;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
.ant-select-multiple .ant-select-selection-item {
  background: #e2eafc;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  padding: 3px 6px;
  height: initial;
  border-radius: 4px;
  margin: 6px;
}
.ant-select-multiple .ant-select-selection-item-remove {
  vertical-align: 0;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  background: #7996d9;
  padding: 4px;
  border-radius: 3px;
  font-size: 8px;
  vertical-align: 1px;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon svg {
  fill: #ffffff;
}
.option-mul {
  margin-bottom: 5px;
}
.ant-select-selection-overflow-item {
  min-height: 38px;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #00338d;
  border-radius: 6px;
  color: #ffffff;
}
.rc-virtual-list-holder-inner {
  padding: 5px 10px;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  box-shadow: none;
}
.ant-popover-inner-content {
  color: #000000;
  white-space: pre;
}
.ant-popover-inner {
  box-sizing: border-box;
  border-radius: 8px;
}
.ant-number-hide-arrow .ant-input-number-handler-wrap {
  display: none;
}
.text-area-field {
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #00338d;
  border-radius: 5px;
}
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-menu-sub.ant-menu-inline {
  background: #000c17!important;
  color: #ffffff!important;
}
.ant-menu-submenu-popup li span {
  color: #000000;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item > .ant-menu-title-content > div {
  opacity: 0;
}
.store-name {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.text-right {
  text-align: right;
}
.inline-flex {
  display: inline-flex;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.ant-page-header .ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: center;
}